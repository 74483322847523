
import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/shared/Header.vue'
// import { State } from "vuex-class";

@Component({
  components: {
    Header,
  },
})
export default class TicketInfoHeader extends Vue {
  // @State(({ userState }) => userState.userName) name!: string;
  // @State(({ userState }) => userState.userPoints) points!: string;
}
