
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'

import TicketInfoHeader from '@/components/TicketInfo/TicketInfoHeader.vue'

import TicketInfoBody from '@/components/TicketInfo/TicketInfoBody.vue'

@Component({
  components: {
    TicketInfoHeader,
    TicketInfoBody,
  },
})
export default class MyCards extends Vue {}
