
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
/* HELPERS */
import { eventDate, eventDateTime, userYear } from '@/helpers/date'
@Component
export default class TicketInfoBody extends Vue {
  @State(({ receptionistState }) => receptionistState.assistant)
  assistant!: any
  @State(({ receptionistState }) => receptionistState.isPayed)
  isPayed!: any
  @State(({ receptionistState }) => receptionistState.isVerified)
  isVerified!: any
  @Action updateAssistantStatus!: Function

  get eventDateInit(): string {
    return eventDate(this.assistant.event.initial_date)
  }
  get eventDateEnd(): string {
    return eventDate(this.assistant.event.finish_date)
  }
  get eventDateTimeInit(): string {
    return eventDateTime(this.assistant.event.initial_date)
  }
  get eventDateTimeEnd(): string {
    return eventDateTime(this.assistant.event.finish_date)
  }
  get userBirthDayDate(): number {
    return userYear(this.assistant.birthday)
  }

  get hasCovid(): string {
    return this.assistant.covid_simptoms === 0 ? 'NO' : 'SI'
  }

  get getGender(): string {
    if (this.assistant.gender === 'male') return 'Masculino'
    if (this.assistant.gender === 'female') return 'Femenino'
    else return 'Otro'
  }

  async updateStatus(): Promise<any> {
    if (this.isVerified && this.isPayed) {
      this.$router.push('/qr-scan')
    } else {
      const loader = Vue.$loading.show()

      await this.updateAssistantStatus({ uuid: this.assistant.uuid })
      loader.hide()

      this.$router.push('/qr-scan')
    }
  }

  get action(): string {
    if (this.isPayed && this.isVerified) {
      return 'Ya verificado'
    } else if (this.isPayed && !this.isVerified) {
      return 'Marcar como verificado'
    } else {
      return 'Marcar como pagado y verificado'
    }
  }

  get btnStyles() {
    if (this.isPayed && this.isVerified) {
      return 'app-btn__text-verified white color-btn'
    } else if (this.isPayed && !this.isVerified) {
      return 'app-btn__primary white color-btn'
    } else {
      return 'app-btn__secondary white color-btn'
    }
  }

  get statusStyles() {
    return this.isVerified == 0 ? 'bg-success' : 'bg-danger'
  }
}
